import React, { useState } from 'react';
import { Result, Button, Form, Input, Spin, Divider } from 'antd';
import { useHistory } from 'react-router-dom';
import MailIcon from '@mui/icons-material/Mail';
import { useApolloClient } from '@apollo/client';
import axios from 'axios';
import Swal from 'sweetalert2';

import CustomInput from '../../../custom-components/input/CustomInput';
import { fetchUserByEmail } from '../../queries/userQuery';

export const ResetPassworScreen = () => {
    const apolloClient = useApolloClient();
    const [form] = Form.useForm();
    const history = useHistory();

    const [loading, setLoading] = useState(false);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 24 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 24 },
        },
    };

    const sendMail = async (data) => {
        axios({
            method: 'post',
            url: process.env.REACT_APP_FUNCTION_SEND_EMAIL_RESTORE_PASSWORD_URL,
            data: {
                id: data.id,
                email: data.email,
                displayName: data.displayName,
                origin: window.location.origin
            }
        }).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    title: '¡Correo enviado con éxito!',
                    text: "Revisa tu bandeja de entrada para restablecer tu contraseña.",
                    icon: 'success', 
                    confirmButtonColor: '#D10505',
                    confirmButtonText: 'Aceptar',
                    allowOutsideClick: false
                });
                
            } else {
                console.warn('Respuesta inesperada. Estado HTTP:', res.status);
            }
        }).catch(error => {
            console.error('Error al enviar el correo:');
        });
    };
    

   

    const handleSubmit = (values) => {
        const { email } = values;
        if (!email) {
            return;
        }
    
        setLoading(true);
    
        apolloClient.query({
            query: fetchUserByEmail,
            variables: { email }
        }).then(async ({ data }) => {
    
            if (data.users.length > 0) {
                const { id, email, displayName } = data.users[0];
    
                const userData = { id, email, displayName };
    
                await sendMail(userData);
            } else {
                console.warn('No se encontró ningún usuario con ese correo.');
                Swal.fire({
                    title: 'El correo electrónico no está registrado',
                    text: "Por favor, verifique el correo electrónico ingresado.",
                    icon: 'error',
                    confirmButtonColor: '#D10505',
                    confirmButtonText: 'Aceptar',
                    allowOutsideClick: false
                });                
            }
        }).catch(error => {
            console.error('Error al consultar el usuario:', error);
        }).finally(() => {
            setLoading(false);
        });
    };



    if (loading) {
        return (
            <center
                style={{
                    height: window.innerHeight,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center"
                }}
            >
                <Spin size='large' />
            </center>
        )
    }

    return (
        <div
            // style={{
            //     height: '70vh',
            //     display: 'flex',
            //     justifyContent: 'center',
            //     alignItems: 'center',
            // }}
            className='login-wrap'
        >
            <Result
                icon={<img src="https://navstorageapps.blob.core.windows.net/nav-cooltrack-evidences/image-BrandingSmallUM.jpg" width="250" />}
                title={`Restablecer contraseña`}
                className='login-form'
                extra={[
                    <Form
                        key='form'
                        form={form}
                        name='verification'
                        onFinish={handleSubmit}
                        {...formItemLayout}
                    >
                        <Form.Item
                            label='Correo electrónico'
                            name='email'
                            hasFeedback
                            rules={[
                                {
                                    type: 'email',
                                    message: 'Correo electrónico no válido',
                                },
                                {
                                    required: true,
                                    message: 'Ingrese correo electrónico',
                                },
                            ]}
                        >
                            <CustomInput
                                autoFocus={true}
                                placeholder="Correo electrónico"
                                prefix={<MailIcon />}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" shape='round' size='large' style={{ backgroundColor: '#D10505', borderColor: '#D10505' }} onClick={handleSubmit}>
                                Verificar
                            </Button>
                            <Divider type='vertical' />
                            <Button
                                type="primary"
                                style={{ backgroundColor: '#D10505', borderColor: '#D10505' }}
                                shape='round'
                                size='large'
                                onClick={() => history.push('/login')}
                            >
                                Regresar
                            </Button>
                        </Form.Item>
                    </Form>
                ]}
            />
        </div>
    );
};
